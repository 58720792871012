import { useCallback } from "react";
import { useAppSelector } from "@/store/store";
import useGetDefaultLocation from "@/hooks/useGetDefaultLocation";
import { useUser } from "@/user/UserContext";
import { getBetSites, getROIRestrictedBetSites } from "@/hooks/useGetBetSites";
import { useUserSettingsContextSelector } from "@/user/user-settings/UserSettingsContext";
import getSportsOfLeague from "@/utis/getSportsOfLeague";
import { useRestrictedBetSitesFunction } from "@/hooks/useRestrictedBetSites";
import generateCommonVariableForSubsriptionFromVariable, {
  GenerateCommonVariableForSubsriptionFromVariableType,
} from "@/helper/generateCommonVariableForSubsriptionFromVariable";
import { InputIncludeExcludeRequestType } from "@/components/Block/Exclude/common";
import { InputFilterBaseRequestType } from "@/user/user-filters/InputFilterBaseRequestType";
import { useRestrictedLeaguesFunction } from "@/hooks/useRestrictedLeagues";
import { BetMarketTypeEnum } from "../Enums/BetMarketTypeEnum";
import { BET_MARKET_TYPE_GROUP_ENUM } from "../Enums/BetMarketTypeGroupEnum";
import { League } from "../Enums/LeagueEnum";
import { FilterEnum } from "./FilterList";
import { Sports } from "../Enums/SportsEnum";
import isPackageTypeFreeFn from "../../../helper/isPackageTypeFree";
import { CommonFilterType, hasPageRestrictionLimit, KeyOfPages } from "./commonFilterType";
import { UserAdvanceFilterType } from "./FilterContext.common";

function isFilterRestricted(filterEnum: FilterEnum) {
  return (
    filterEnum === FilterEnum.SPORTSBOOKS_RESTRICTED ||
    filterEnum === FilterEnum.LEAGUES_RESTRICTED ||
    filterEnum === FilterEnum.SPORTS_RESTRICTED
  );
}

// Fix rendering issue in this, like why the variables are consider different even they are same as preious
// This will also fix this issue to all these components
function useConvertAdvanceFilterToQueryVariableFn() {
  const user = useUser();
  const { sportsBookEnums } = useUserSettingsContextSelector((ctx) => ctx.globalSettings);
  const defaultlocation = useGetDefaultLocation();
  const restrictedLeaguesFn = useRestrictedLeaguesFunction();
  const restrictedBetSitesFn = useRestrictedBetSitesFunction();
  const { betSitesParent } = useAppSelector((state) => state.constantsReducer);
  const betSitesConstant = useAppSelector((state) => state.constantsReducer.betSites);
  const sportsData = useAppSelector((state) => state.constantsReducer.sports);

  const generateVariableAdvFilter = useCallback(
    (filters: GenerateCommonVariableForSubsriptionFromVariableType["queryState"], pathkey: KeyOfPages) => {
      const allSportsBookData = betSitesConstant.map((sp) => sp.enumValue);
      const restrictedBetSites = restrictedBetSitesFn(filters.sportsBook);
      const restrictedLeagues = restrictedLeaguesFn(filters.leagues);
      const betSites = getBetSites({
        sportsBook: filters.sportsBook,
        sportsBookEnums,
        restrictions: user.restrictions,
        restrictedBetSites,
      });

      const rOIRestrictedBetSites = getROIRestrictedBetSites({
        betSites,
        rOIRestrictedBetSites: filters.rOIRestrictedBetSites,
        sportsBookEnums,
        sportbookLimit: user.restrictions.sportbookLimit,
        allSportsBookData,
        pathKey: pathkey,
      });

      return generateCommonVariableForSubsriptionFromVariable({
        queryState: filters,
        user,
        defaultlocation,
        restrictedLeagues,
        betSites,
        rOIRestrictedBetSites,
        betSitesParent,
        pathkey,
      });
    },
    [betSitesConstant, betSitesParent, defaultlocation, restrictedBetSitesFn, restrictedLeaguesFn, user, sportsBookEnums],
  );

  return useCallback((pathkey: KeyOfPages, queryState: CommonFilterType, getAdvanceFilter: (advanceFilterProp?: string) => UserAdvanceFilterType[] | undefined) => {
    const userAdvFilReqList: Array<InputFilterBaseRequestType> = [];
    const isPackageTypeFreeAndDataLimited = isPackageTypeFreeFn(user.data?.activePackageType) && hasPageRestrictionLimit(pathkey);
    const userAdvanceFilter = getAdvanceFilter(queryState.advanceFilter);
    userAdvanceFilter?.forEach((userAdvFil) => {
      const requestData = generateVariableAdvFilter(userAdvFil.filters, pathkey).variables;
      if (!queryState.incExcNotApplyAllAdvFltr) {
        requestData.includeExcludes = queryState.includeExcludes?.map(({ expirationDate, __typename, ...rest }) => rest) as Omit<InputIncludeExcludeRequestType, 'expirationDate'>[];
        requestData.isInclude = queryState.isInclude;
      }
      const userAdvFilReq: InputFilterBaseRequestType = {
        requestData,
        requestHash: userAdvFil.requestHash,
      };
      if (hasPageRestrictionLimit(pathkey)) {
        userAdvFilReq.isRestricted = isPackageTypeFreeAndDataLimited ? true : isFilterRestricted(userAdvFil.filterEnum);
      }
      if (userAdvFil.filterEnum === FilterEnum.SPORTSBOOKS) {
        if (queryState.sportsBook && !queryState.sportsBook?.includes(userAdvFil.filterKey)) {
          return;
        }
        userAdvFilReq.betSite = userAdvFil.filterKey;
      }
      if (userAdvFil.filterEnum === FilterEnum.SPORTSBOOKS_RESTRICTED) {
        if (queryState.rOIRestrictedBetSites && !queryState.rOIRestrictedBetSites?.includes(userAdvFil.filterKey)) {
          return;
        }
        userAdvFilReq.betSite = userAdvFil.filterKey;
      }
      if (userAdvFil.filterEnum === FilterEnum.LEAGUES) {
        if (queryState.leagues && !queryState.leagues?.includes(userAdvFil.filterKey as League)) {
          return;
        }
        userAdvFilReq.league = userAdvFil.filterKey as League;
        // We cant have advance filter of league, sports contain league because advance filter of league with league is not possible
        userAdvFilReq.requestData.leagues = [userAdvFilReq.league];
      }
      if (userAdvFil.filterEnum === FilterEnum.LEAGUES_RESTRICTED) {
        if (queryState.rOIRestrictedLeagues && !queryState.rOIRestrictedLeagues?.includes(userAdvFil.filterKey as League)) {
          return;
        }
        userAdvFilReq.league = userAdvFil.filterKey as League;
        userAdvFilReq.requestData.rOIRestrictedLeagues = [userAdvFilReq.league];
      }
      if (userAdvFil.filterEnum === FilterEnum.BET_TYPES) {
        if (queryState.betMarketInfo && !queryState.betMarketInfo?.includes(userAdvFil.filterKey)) {
          return;
        }
        userAdvFilReq.betMarketType = userAdvFil.filterKey as BetMarketTypeEnum;
      }
      if (userAdvFil.filterEnum === FilterEnum.BET_TYPES_GROUP) {
        if (queryState.betMarketTypeGroups && !queryState.betMarketTypeGroups?.includes(userAdvFil.filterKey)) {
          return;
        }
        userAdvFilReq.betMarketTypeGroup = userAdvFil.filterKey as BET_MARKET_TYPE_GROUP_ENUM;
      }
      if (userAdvFil.filterEnum === FilterEnum.SPORTS) {
        if (
          queryState.leagues &&
          !queryState.leagues.filter((league) => getSportsOfLeague(league) === userAdvFil.filterKey).length
        ) {
          return;
        }
        userAdvFilReq.sport = userAdvFil.filterKey as Sports;

        userAdvFilReq.requestData.leagues = sportsData?.find((sport) => sport.sportEnum === userAdvFilReq.sport)?.leagues.map((leag) => leag.leagueEnum);
      }
      if (userAdvFil.filterEnum === FilterEnum.SPORTS_RESTRICTED) {
        if (
          queryState.rOIRestrictedLeagues &&
          !queryState.rOIRestrictedLeagues.filter((league) => getSportsOfLeague(league) === userAdvFil.filterKey).length
        ) {
          return;
        }
        userAdvFilReq.sport = userAdvFil.filterKey as Sports;

        userAdvFilReq.requestData.rOIRestrictedLeagues = sportsData?.find((sport) => sport.sportEnum === userAdvFilReq.sport)?.leagues.map((leag) => leag.leagueEnum);;
      }
      userAdvFilReqList.push(userAdvFilReq);
    });
    return userAdvFilReqList;
  }, [generateVariableAdvFilter, sportsData, user.data?.activePackageType]);
}

export default useConvertAdvanceFilterToQueryVariableFn;
