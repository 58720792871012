import { isToday } from "date-fns";
import { convertUTCToLocalTime } from "./ApiDateFormats";

const getDayWithSuffix = (day: number) => {
  if (day > 3 && day < 21) return `${day}th`;
  switch (day % 10) {
    case 1: return `${day}st`;
    case 2: return `${day}nd`;
    case 3: return `${day}rd`;
    default: return `${day}th`;
  }
};

export const formatDateWithFullMonthAndSuffix = (date: Date, locale = "en") => {
  const dateFormatter = new Intl.DateTimeFormat(locale, { month: "long", day: "numeric", year: "numeric" });
  const formattedDate = dateFormatter.formatToParts(date).map(({ type, value }) => {
    if (type === 'day') return getDayWithSuffix(parseInt(value, 10));
    return value;
  }).join('');

  return formattedDate;
};


export const formatDateTimeFull = (date: Date | number, convertToLocal?: boolean, locale = "en") => {
  const localDate = convertToLocal ? convertUTCToLocalTime(date) : date;
  if (!localDate) return null;
  const timeFormatter = new Intl.DateTimeFormat(locale, { hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: true });
  const time = timeFormatter.format(localDate).replace('AM', 'am').replace('PM', 'pm');
  const dateFormatter = new Intl.DateTimeFormat(locale, { month: "2-digit", day: "2-digit" });
  const formattedDate = dateFormatter.format(localDate);

  return <>{formattedDate}, <strong>{time}</strong></>;
};

export const formatDateTime = (date: Date, locale = "en") => {
  const timeFormatter = new Intl.DateTimeFormat(locale, { hour: "2-digit", minute: "2-digit", hour12: true });
  const time = timeFormatter.format(date).replace('AM', 'am').replace('PM', 'pm');

  if (isToday(date)) {
    return time;
  }

  const dateFormatter = new Intl.DateTimeFormat(locale, { month: "2-digit", day: "2-digit" });
  const formattedDate = dateFormatter.format(date);

  return `${formattedDate}, ${time}`;
};

export const formatDateTimeYear = (date: Date, locale = "en") => {
  const timeFormatter = new Intl.DateTimeFormat(locale, {
    hour: "numeric",
    minute: "2-digit",
    hour12: true
  });
  const time = timeFormatter.format(date)
    .replace(/\s*(AM|PM)/i, match => match.trim().toLowerCase());

  if (isToday(date)) {
    return time;
  }

  const dateFormatter = new Intl.DateTimeFormat(locale, {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit"
  });
  const formattedDate = dateFormatter.format(date);

  return `${formattedDate}, ${time}`;
};

export const formatDateTimeYearAt = (date: Date, locale = "en") => {
  const timeFormatter = new Intl.DateTimeFormat(locale, { hour: "2-digit", minute: "2-digit", hour12: true });
  const time = timeFormatter.format(date).replace('AM', 'am').replace('PM', 'pm');

  if (isToday(date)) {
    return <strong>{time}</strong>;
  }

  const dateFormatter = new Intl.DateTimeFormat(locale, { month: "2-digit", day: "2-digit", year: "2-digit" });
  const formattedDate = dateFormatter.format(date);

  return <>{formattedDate} @ {time}</>;
};