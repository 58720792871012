import { CommonFilterType, CommonFilterVariableType } from '@/components/Common/Filters/commonFilterType'


function removeSubscriptionVariableFromCommonFilter(filter: CommonFilterType): CommonFilterVariableType {
  return {
    // startDateTime: filter.startDateTime,
    // endDateTime: filter.endDateTime,
    // sportsBook: filter.sportsBook?.filter((sp) => betSiteMap[sp].isSisterSite),
    // rOIRestrictedBetSites: filter.rOIRestrictedBetSites?.filter((sp) => betSiteMap[sp].isSisterSite),

    betAmount: filter.betAmount,
    search: filter.search,
    // betMarketInfo: filter.betMarketInfo,
    region: filter.region,
    showAllSportsBook: filter.showAllSportsBook,
    kellyCriterion: filter.kellyCriterion,
    roundingValues: filter.roundingValues,
    mustIncludeBetSite: filter.mustIncludeBetSite,
    graphBetSites: filter.graphBetSites,
    displayedMaxOdds: filter.displayedMaxOdds,
    displayedMinOdds: filter.displayedMinOdds,


    // Ev sorting
    evBetAmountSort: filter.evBetAmountSort,

    // search filter
    incExcNotApplyAllAdvFltr: filter.incExcNotApplyAllAdvFltr, // not to send to backend

    // ROI Notification
    newDataNotification: filter.newDataNotification,
    roiNotification: filter.roiNotification,

    // TODO: This should not be optional but we don't have a way for types based on page
    // EV+ filters
    // evWeights: filter.evWeights, // This filter has been changes has force need to create a name
    // evFormulaWeight: filter.evFormulaWeight, // This filter has been changes has force need to create a name
    showAllFormulas: filter.showAllFormulas,
    widthWeight: filter.widthWeight,

    // Advance Filters
    advanceFilter: filter.advanceFilter,

    // odds control filter
    holdColumn: filter.holdColumn,
    oddsSort: filter.oddsSort,
    holdColumnSort: filter.holdColumnSort,
    oddWeight: filter.oddWeight,
    avgColumn: filter.avgColumn,
    bestColumn: filter.bestColumn,
    devigAverage: filter.devigAverage,
    betValueView: filter.betValueView,
    hideNoOddsSportsBooks: filter.hideNoOddsSportsBooks,
    devigFormulaCase: filter.devigFormulaCase,
    devigFormulaWeight: filter.devigFormulaWeight,

    // oddsType filter
    oddsType: filter.oddsType,

    // Only used for Sports advance filters
    sports: filter.sports,

  };
}

export default removeSubscriptionVariableFromCommonFilter;