import { useCallback } from "react";
import { useUser } from "@/user/UserContext";
import { useUserSettingsContextSelector } from "@/user/user-settings/UserSettingsContext";
import { useAppSelector } from "@/store/store";
import { Filters } from "@/user/user-settings/common";
import { UserRestrictionType } from "@/user/UserRestrictions/common";
import { hasPageRestrictionLimit, KeyOfPages } from "@/components/Common/Filters/commonFilterType";
import useRestrictedBetSites, { useRestrictedBetSitesFunction } from "./useRestrictedBetSites";

// We have made this function as well instead of just jook to use it with advance filter as well.

type GetBetSitesType = {
  sportsBook: string[] | undefined;
  sportsBookEnums: Filters["sportsBookEnums"];
  restrictions?: UserRestrictionType;
  restrictedBetSites: ReturnType<typeof useRestrictedBetSites>;
};

export const getBetSites = ({ sportsBook, sportsBookEnums, restrictions, restrictedBetSites }: GetBetSitesType) => {
  if (restrictions?.sportbookLimit) return restrictedBetSites;
  if (sportsBook) {
    return sportsBookEnums?.length ? sportsBook.filter((sb) => sportsBookEnums?.includes(sb)) : sportsBook;
  }
  return sportsBookEnums;
};

type GetROIRestrictedBetSites = {
  betSites: string[] | undefined;
  rOIRestrictedBetSites: string[] | undefined;
  sportsBookEnums: Filters["sportsBookEnums"];
  sportbookLimit: UserRestrictionType["sportbookLimit"];
  allSportsBookData: string[];
  pathKey: KeyOfPages;
};

export const getROIRestrictedBetSites = ({
  betSites,
  rOIRestrictedBetSites,
  sportsBookEnums,
  sportbookLimit,
  allSportsBookData,
  pathKey,
}: GetROIRestrictedBetSites) => {
  if (!hasPageRestrictionLimit(pathKey)) return undefined;
  if (!sportbookLimit) return [];
  return rOIRestrictedBetSites || sportsBookEnums || allSportsBookData;
};

// function useGetBetSites(sportsbook: string[] | undefined) {
//   const sportsBookEnums = useUserSettingsContextSelector((ctx) => ctx.globalSettings.sportsBookEnums);
//   const { restrictions } = useUser();
//   const restrictedBetSites = useRestrictedBetSites(sportsbook);
//   const queryState = useFilterContextSelector((ctx) => ctx.filterState);
//   const pathKey = useGetPathKey();

//   const allSportsBookData = useAppSelector((state) => state.constantsReducer.betSites).map((sp) => sp.enumValue);

//   const betSites = useMemo(
//     () =>
//       getBetSites({
//         sportsBook: queryState.sportsBook,
//         sportsBookEnums,
//         restrictions,
//         restrictedBetSites,
//       }),
//     [queryState.sportsBook, restrictedBetSites, restrictions, sportsBookEnums],
//   );

//   const rOIRestrictedBetSites = useMemo(
//     () =>
//       getROIRestrictedBetSites({
//         betSites,
//         rOIRestrictedBetSites: queryState.rOIRestrictedBetSites,
//         sportsBookEnums,
//         sportbookLimit: restrictions.sportbookLimit,
//         allSportsBookData,
//         pathKey,
//       }),
//     [allSportsBookData, betSites, pathKey, queryState.rOIRestrictedBetSites, restrictions.sportbookLimit, sportsBookEnums],
//   );

//   return { betSites, rOIRestrictedBetSites };
// }

export function useGetBetSitesFn() {
  const sportsBookEnums = useUserSettingsContextSelector((ctx) => ctx.globalSettings.sportsBookEnums);
  const { restrictions } = useUser();
  const restrictedBetSitesFn = useRestrictedBetSitesFunction();
  const betSitesConstant = useAppSelector((state) => state.constantsReducer.betSites);

  return useCallback(
    (sportsbookQueryState: string[] | undefined, rOIRestrictedBetSitesQueryState: string[] | undefined, pathKey: KeyOfPages) => {
      const restrictedBetSites = restrictedBetSitesFn(sportsbookQueryState);
      const allSportsBookData = betSitesConstant.map((sp) => sp.enumValue);

      const betSites = getBetSites({
        sportsBook: sportsbookQueryState,
        sportsBookEnums,
        restrictions,
        restrictedBetSites,
      });

      const rOIRestrictedBetSites = getROIRestrictedBetSites({
        betSites,
        rOIRestrictedBetSites: rOIRestrictedBetSitesQueryState,
        sportsBookEnums,
        sportbookLimit: restrictions.sportbookLimit,
        allSportsBookData,
        pathKey,
      });
      return { betSites, rOIRestrictedBetSites };
    },
    [betSitesConstant, restrictedBetSitesFn, restrictions, sportsBookEnums],
  );
}

export default useGetBetSitesFn;
