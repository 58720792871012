import { useUser } from "@/user/UserContext";
import { ServerStatusType } from "@/store/toolsSportCounts";
import { useCallback, useMemo } from "react";
import { League } from "@/components/Common/Enums/LeagueEnum";
import isNilThenUndefined from "@/utis/isNilThenUndefined";
import { KeyOfPages } from "@/components/Common/Filters/commonFilterType";

export function getLeagueCountKeyFromPathKey(pathKey: KeyOfPages): keyof ServerStatusType {
  if (pathKey === "arbitrages") return "arbitrageCountByLeague";
  if (pathKey === "middles") return "middleCountByLeague";
  if (pathKey === "freebet") return "freeBetCountByLeague";
  if (pathKey === "lowhold") return "lowHoldCountByLeague";
  if (pathKey === "expectedvalue") return "expectedValueCountByLeague";
  return "arbitrageCountByLeague";
}

// export function useGetLeagueSorted() {
//   const pathname = usePathname();
//   const countRef = useRef<League[]>();
//   const [, pathKey] = pathname.split("/") as KeyOfPages[];
//   const leaguesList = useAppSelector((state) => state.constantsReducer.leagues);
//   const leaguesCount = useAppSelector((state) => state.toolsSportCountsReducer[getLeagueCountKeyFromPathKey(pathKey)]);
//   if (countRef.current?.length) return countRef.current;
//   // League Count is not avaialbe at intial load. So if the user has restriction, it will first used the default league and then the league sort for restricted league
//   if (!leaguesCount || !Array.isArray(leaguesCount) || !leaguesCount.length) return leaguesList?.map((lg) => lg.id);
//   countRef.current = leaguesList
//     ?.map((league, i) => ({ ...league, count: leaguesCount[i] }))
//     .sort((a, b) => b.count - a.count)
//     .map((lg) => lg.id);
//   return countRef.current;
// }

export function useRestrictedLeaguesFunction() {
  const user = useUser();

  return useCallback(
    (leagues: League[] | undefined | null) => {
      if (!user.restrictions.leagueLimit) return undefined;
      if (leagues && leagues.length >= user.restrictions.leagueLimit) {
        return leagues.slice(0, user.restrictions.leagueLimit);
      }
      const restrictedLeagues = [League.NFL, League.NBA, League.NHL]?.slice(0, isNilThenUndefined(user.restrictions.leagueLimit));
      if (leagues) {
        return leagues.concat(restrictedLeagues?.slice(0, user.restrictions.leagueLimit - leagues.length) || []);
      }
      return restrictedLeagues;
    },
    [user.restrictions.leagueLimit],
  );
}

export default function useRestrictedLeagues(leagues: League[] | undefined) {
  const restrictedLeagues = useRestrictedLeaguesFunction();
  const restrictedData = useMemo(() => restrictedLeagues(leagues), [restrictedLeagues, leagues]);
  return restrictedData;
}
