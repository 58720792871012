
export type Converters<T> = {
  into: (value: T) => string;
  from: (value: string) => T | undefined;
};

export type ConvertersWithDefaultValue<T> = Converters<T> & {
  readonly defaultValue: T;
};

export type ConvertersWithDefaultValueFactory<T> = Converters<T> & {
  withDefault: (defaultValue: T) => ConvertersWithDefaultValue<T>;
};

type QueryTypeMap = Readonly<{
  string: ConvertersWithDefaultValueFactory<string>;
  stringEnum<Enum extends string>(validValues: Enum[]): ConvertersWithDefaultValueFactory<Enum>;
  stringTyped<T extends string>(): ConvertersWithDefaultValueFactory<T>;
  integer: ConvertersWithDefaultValueFactory<number>;
  integerTyped<T extends number>(): ConvertersWithDefaultValueFactory<T>;
  float: ConvertersWithDefaultValueFactory<number>;
  boolean: ConvertersWithDefaultValueFactory<boolean>;
  array<ItemType>(itemConverter: Converters<ItemType>, separator?: string): ConvertersWithDefaultValueFactory<ItemType[]>;
  json<T>(): ConvertersWithDefaultValueFactory<T>;
}>;

export const queryTypes: QueryTypeMap = {
  string: {
    from: (v) => v,
    into: String,
    withDefault(defaultValue) {
      return {
        ...this,
        defaultValue,
      };
    },
  },
  stringEnum<Enum extends string>(validValues: Enum[]) {
    return {
      from: (query: string) => {
        const asEnum = query as unknown as Enum;
        return validValues?.includes(asEnum) ? asEnum : undefined;
      },
      into: String,
      withDefault(defaultValue) {
        return {
          ...this,
          defaultValue,
        };
      },
    };
  },
  stringTyped<T extends string>() {
    return queryTypes.string as unknown as ConvertersWithDefaultValueFactory<T>;
  },
  integer: {
    from: (v) => {
      const parsedValue = parseInt(v, 10);
      return Number.isSafeInteger(parsedValue) ? parsedValue : undefined;
    },
    into: (v) => Math.floor(v).toString(),
    withDefault(defaultValue) {
      return {
        ...this,
        defaultValue,
      };
    },
  },
  integerTyped<T extends number>() {
    return queryTypes.integer as unknown as ConvertersWithDefaultValueFactory<T>;
  },
  float: {
    from: (v) => parseFloat(v),
    into: String,
    withDefault(defaultValue) {
      return {
        ...this,
        defaultValue,
      };
    },
  },
  boolean: {
    from: (v) => v === "true",
    into: String,
    withDefault(defaultValue) {
      return {
        ...this,
        defaultValue,
      };
    },
  },
  array(itemConverter, separator = ",") {
    return {
      from: (query) => {
        type ItemType = NonNullable<ReturnType<typeof itemConverter.from>>;
        const items = query
          .split(separator)
          .map((item) => decodeURIComponent(item))
          .map(itemConverter.from)
          .filter((value) => value !== null && value !== undefined) as ItemType[];
        return items.length ? items : undefined;
      },
      into: (values) => {
        const { into: stringify } = itemConverter;
        return values.map(stringify).map(encodeURIComponent).join(separator);
      },
      withDefault(defaultValue) {
        return {
          ...this,
          defaultValue,
        };
      },
    };
  },
  json<T>() {
    return {
      from: (v: string) => {
        try {
          return JSON.parse(v) as T;
        } catch {
          return undefined;
        }
      },
      into: (value: T) => JSON.stringify(value),
      withDefault(defaultValue: T) {
        return {
          ...this,
          defaultValue,
        };
      },
    };
  },
};
