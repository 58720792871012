import { ServerStatusType } from "@/store/toolsSportCounts";
import { useAppSelector } from "@/store/store";
import { useUser } from "@/user/UserContext";
import { usePathname } from "@/navigation";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useUserSettingsContextSelector } from "@/user/user-settings/UserSettingsContext";
import isNilThenUndefined from "@/utis/isNilThenUndefined";
import { KeyOfPages } from "@/components/Common/Filters/commonFilterType";

export function getBetSiteCountKeyFromPathKey(pathKey: KeyOfPages): keyof ServerStatusType {
  if (pathKey === "arbitrages") return "arbitrageCountByBetSite";
  if (pathKey === "middles") return "middleCountByBetSite";
  if (pathKey === "freebet") return "freeBetCountByBetSite";
  if (pathKey === "lowhold") return "lowHoldCountByBetSite";
  if (pathKey === "expectedvalue") return "expectedValueCountByBetSite";
  return "arbitrageCountByBetSite";
}

// Currently not ued anywhere
export function useGetBetSitesSorted() {
  const pathname = usePathname();
  const countRef = useRef<string[]>();
  const [hasData, setHasData] = useState(false);
  const [, pathKey] = pathname.split("/") as KeyOfPages[];
  const betSiteList = useAppSelector((state) => state.constantsReducer.betSites);
  const betSiteCount = useAppSelector((state) => state.toolsSportCountsReducer[getBetSiteCountKeyFromPathKey(pathKey)]);

  useEffect(() => {
    if (!hasData && betSiteCount?.length) {
      setHasData(true);
    }
  }, [betSiteCount, hasData]);

  const betSiteSorted = useMemo(() => {
    if (countRef.current?.length && hasData) return countRef.current;
    // Dont return undefiend, If sorted coutn is not there send without sorted list
    if (!betSiteCount || !Array.isArray(betSiteCount) || !betSiteCount.length) return betSiteList?.map((lg) => lg.enumValue);
    countRef.current = betSiteList
      ?.map((site) => ({ ...site, count: betSiteCount[site.id] }))
      .sort((a, b) => b.count - a.count)
      .map((lg) => lg.enumValue);
    return countRef.current;
    // We don't want this computed everytime betSiteCount changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betSiteList, hasData]);
  return betSiteSorted;
}

const sortAndFilterSportsBook = (sportsBook: string[] | undefined, limits?: number | null) => {
  if (sportsBook !== undefined && sportsBook.length === 0) return sportsBook;
  return sportsBook?.slice(0, isNilThenUndefined(limits));
};

export function useRestrictedBetSitesFunction() {
  const user = useUser();
  const globalSportsBook = useUserSettingsContextSelector((ctx) => ctx.globalSettings.sportsBookEnums);
  const allSportsBookData = useAppSelector((state) => state.constantsReducer.betSites);

  const allSportsBookDataEnum = useMemo(() => allSportsBookData.map((sp) => sp.enumValue), [allSportsBookData]);

  // Get the Global User Setting SportsBook Sorted and restricted by sportsbook limit
  const globalSportsBookLimited = useMemo(
    () => sortAndFilterSportsBook(globalSportsBook || allSportsBookDataEnum),
    [globalSportsBook, allSportsBookDataEnum],
  );

  return useCallback(
    (sportsbook: string[] | undefined) => {
      // Get the User Page SportsBook Filter Which are present in the global setting,
      // sorted and restricted by sportsbook limit
      const currPageFiltersSportsbookLimited = sortAndFilterSportsBook(
        sportsbook || globalSportsBookLimited,
        user.restrictions.sportbookLimit,
      );
      const restrictedBetSites = currPageFiltersSportsbookLimited || globalSportsBookLimited;
      return restrictedBetSites;
    },
    [globalSportsBookLimited, user.restrictions.sportbookLimit],
  );
}

export default function useRestrictedBetSites(sportsbook: string[] | undefined) {
  const restrictedBetSites = useRestrictedBetSitesFunction();
  const restrictedSportsBook = useMemo(() => restrictedBetSites(sportsbook), [restrictedBetSites, sportsbook]);
  return restrictedSportsBook;
}
