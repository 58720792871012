import { ConstantsType } from "@/store/constants";
import { useAppSelector } from "@/store/store";

export function getBetSiteFromSister(betSitesParent: ConstantsType["betSitesParent"], betSite?: string) {
  if (!betSite) return undefined;
  return betSitesParent[betSite] ? betSitesParent[betSite]! : betSite;
}

export function useGetBetSiteFromSister(betSite?: string) {
  const { betSitesParent } = useAppSelector((state) => state.constantsReducer);
  if (!betSite) return undefined;
  return betSitesParent[betSite] ? betSitesParent[betSite]! : betSite;
}

export function getBetSiteFromSisterList(betSitesParent: ConstantsType["betSitesParent"], betSite?: string[] | string) {
  const uniqBetSites: Record<string, null> = {};

  if (!betSite) return undefined;

  if (!Array.isArray(betSite)) {
    return betSitesParent[betSite] ? [betSitesParent[betSite]!] : [betSite];
  }

  betSite.forEach((bs) => {
    if (!betSitesParent[bs]) {
      uniqBetSites[bs] = null;
    } else {
      uniqBetSites[betSitesParent[bs]!] = null;
    }
  });
  return Object.keys(uniqBetSites);
}

function useGetBetSiteFromSisterList(betSite?: string[] | string) {
  const { betSitesParent } = useAppSelector((state) => state.constantsReducer);
  return getBetSiteFromSisterList(betSitesParent, betSite);
}

export default useGetBetSiteFromSisterList;
