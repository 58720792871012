import { QueryState, queryTypes } from "@/components/QueryState";
import { UpcomingGameStatus, LiveGameStatus } from "@/components/Filters/DateAndTimeFilter.common";
import { defaultExpectedValueFormulaCase } from "@/components/ExpectedValue/helper";
import { InputIncludeExcludeRequestType } from "@/components/Block/Exclude/common";
import { InputCompetitionDataType } from "@/components/SportsCompetitionFilter/Common/CompetitonData";
import { League } from "../Enums/LeagueEnum";
import { ExpectedValueFormulaCaseEnum } from "../Enums/ExpectedValueFormulaEnum";
import { BET_MARKET_TYPE_GROUP_ENUM } from "../Enums/BetMarketTypeGroupEnum";
import { OddsColumnSortValueEnum } from "../Enums/OddsColumnSortValueEnum";
import { WidthWeightEnum } from "../Enums/WidthWeightEnum";

export const MAX_TAKE_DEFAULT = 20;
export const DEFAULT_MIN_WIDTH = 1;

export function createKeyMap() {
  return {
    startDateTime: queryTypes.integer,
    endDateTime: queryTypes.integer,
    futureHours: queryTypes.integer,
    beforeHours: queryTypes.integer,
    foundSecondsAgo: queryTypes.integer,
    upcomingGameStatus: queryTypes.stringEnum(Object.values(UpcomingGameStatus)).withDefault(UpcomingGameStatus.ALL),
    liveGameStatus: queryTypes.stringEnum(Object.values(LiveGameStatus)).withDefault(LiveGameStatus.ALL),
    leagues: queryTypes.array(queryTypes.stringEnum(Object.values(League))),
    // .withDefault(Object.values(Leagues)),
    minRoi: queryTypes.float,
    // maxRoi: isNil(restriction.rOILimit) ? queryTypes.float : queryTypes.float.withDefault(restriction.rOILimit),
    maxRoi: queryTypes.float,
    minAmericanOdds: queryTypes.float,
    maxAmericanOdds: queryTypes.float,
    betAmount: queryTypes.integer.withDefault(200),
    search: queryTypes.string,
    displayedMaxOdds: queryTypes.string,
    displayedMinOdds: queryTypes.string,
    betMarketInfo: queryTypes.array(queryTypes.string),
    betMarketTypeGroups: queryTypes.array(queryTypes.string),
    // Make this dynamic
    // freeBetPrimaryBetSite: queryTypes.string.withDefault("FAN_DUEL"),
    // lowHoldPrimaryBetSite: queryTypes.string.withDefault("FAN_DUEL"),
    region: queryTypes.string,
    sportsBook: queryTypes.array(queryTypes.string),
    // take: queryTypes.integer.withDefault(isNil(restriction.maxResult) ? 50 : restriction.maxResult),
    take: queryTypes.integer,
    showAllSportsBook: queryTypes.boolean.withDefault(false),
    kellyCriterion: queryTypes.float.withDefault(0.1),
    roundingValues: queryTypes.integer,
    mustIncludeBetSite: queryTypes.string,
    minMaxWager: queryTypes.integer,
    competitionData: queryTypes.json<InputCompetitionDataType[]>(),

    // block duplicate bets
    ignoreDuplicateBetSites: queryTypes.boolean,

    // ROI Notification
    newDataNotification: queryTypes.boolean,
    roiNotification: queryTypes.integer,

    // rOIRestrictedBetSites (Not for Free bet and Low hold)
    rOIRestrictedBetSites: queryTypes.array(queryTypes.string),
    rOIRestrictedLeagues: queryTypes.array(queryTypes.stringEnum(Object.values(League))),

    // Arb Filter
    maximumLegs: queryTypes.integer.withDefault(3),

    // search filter
    isInclude: queryTypes.boolean,
    includeExcludes: queryTypes.json<InputIncludeExcludeRequestType[]>(),
    incExcNotApplyAllAdvFltr: queryTypes.boolean, // not to send to backend
    // Medium Filter
    distance: queryTypes.integer,

    // EV+ filters
    // evWeights: queryTypes.string.withDefault(defaultExpectedValueWeights), // This filter has been changes has force need to create a name
    // evFormulaWeight: queryTypes.array(queryTypes.integer).withDefault(EV_FORMULA_WEIGHT_DEFAULT), // This filter has been changes has force need to create a name
    expectedValueFormulaCase: queryTypes
      .stringEnum(Object.values(ExpectedValueFormulaCaseEnum))
      .withDefault(defaultExpectedValueFormulaCase),
    minWidth: queryTypes.integer.withDefault(DEFAULT_MIN_WIDTH),
    showAllFormulas: queryTypes.boolean.withDefault(false),
    widthWeight: queryTypes.stringEnum(Object.values(WidthWeightEnum)).withDefault(WidthWeightEnum.STATIC),

    // Advance Filters
    advanceFilter: queryTypes.string,

    // odds filter
    league: queryTypes.stringEnum(Object.values(League)).withDefault(League.NBA),
    betGroup: queryTypes.stringEnum(Object.values(BET_MARKET_TYPE_GROUP_ENUM)).withDefault(BET_MARKET_TYPE_GROUP_ENUM.MONEYLINE),
    group: queryTypes.string,
    time: queryTypes.string,

    // odds control filter
    holdColumn: queryTypes.boolean.withDefault(false),
    holdColumnSort: queryTypes.stringEnum(Object.values(OddsColumnSortValueEnum)).withDefault(OddsColumnSortValueEnum.TIME),
    oddWeight: queryTypes.array(queryTypes.string).withDefault([]),
    avgColumn: queryTypes.boolean.withDefault(true),
    bestColumn: queryTypes.boolean.withDefault(true),
    devigAverage: queryTypes.boolean.withDefault(false),
    betValueView: queryTypes.boolean.withDefault(false),
  };
}

export type KeyMapType = ReturnType<typeof createKeyMap>;

export type QueryStateKeyMapType = QueryState<ReturnType<typeof createKeyMap>>;
