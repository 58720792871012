import { usePathname, useRouter } from "@/navigation";
import { useSearchParams } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import { addBasePath } from "next/dist/client/add-base-path";
import isNil from "@/utis/isNil";
import usePrevious from "@/hooks/usePrevious";
import useEventCallback from "@/hooks/useEventCallback";
import useIsomorphicLayoutEffect from "@/hooks/useIsomorphicLayoutEffect";
import { parseQueryState, type ConverterKeyMap, type QueryState } from "./queryState";

function unstableShallowNavigate(href: string, navigateType: "push" | "replace" = "push") {
  const updateMethod = navigateType === "push" ? window.history.pushState : window.history.replaceState;
  updateMethod.call(window.history, window.history.state, "", addBasePath(href));
}

// NOTE: URLSearchParamsInit is copypasta from URLSearchParams' constructor
export type URLSearchParamsInit = string[][] | Record<string, string> | string | URLSearchParams;

export type NavigateOptions = {
  // NOTE: next.js 13 does not have support for shallow navigation (not shallow
  // navigation makes a request to the server on each url update).
  //
  // https://github.com/vercel/next.js/issues/49668
  // https://github.com/47ng/next-usequerystate
  // https://github.com/vercel/next.js/discussions/48110
  unstableShallow?: boolean;
};

export type SetSearchParams = (
  nextInit: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
  navigateOpts?: NavigateOptions,
) => void;

// ----

export type UseQueryStateOptions = {
  // query state is always a reflection (with parsers applied) of url search
  // params. sometimes it might be useful to "preserve" url search params
  // between contextual routes.
  restoreOnPathnameChange?: boolean;
  // opt-in to observing server component loading states when doing non-shallow
  // updates by passing a `startTransition` from the `React.useTransition()`
  // hook.
  //
  // if unstableShallow is true - startTransition will be ignored.
  //
  // next.js currently does not support non-shallow navigation, which means that
  // each time state changes - request to the server is made.
  // https://github.com/STRATZ-Esports/frontend/issues/2170#issuecomment-1825516291
  startTransition?: React.TransitionStartFunction;
  // shallow mode (true by default) keeps query states update client-side only,
  // meaning there won't be calls to the server.
  //
  // setting it to `false` will trigger a network request to the server with
  // the updated querystring.
  unstableShallow?: boolean;
};

export type SetQueryState<KM extends ConverterKeyMap> = (
  nextInit: Partial<QueryState<KM>> | ((prevQueryState: QueryState<KM>) => QueryState<KM>),
  clearOthers?: boolean,
) => void;

export type QueryStateSuggar<KM extends ConverterKeyMap> = {
  isQueryStateClean: boolean;
  clearQueryState: () => void;
  searchParams: URLSearchParams;
  setDefaulFilterValues: (val: QueryState<KM> | undefined) => void;
};

type UseQueryStateReturn<KM extends ConverterKeyMap> = [QueryState<KM>, SetQueryState<KM>, QueryStateSuggar<KM>];

export function useQueryState<KM extends ConverterKeyMap>(
  keyMap: KM,
  // eslint-disable-next-line default-param-last
  options: UseQueryStateOptions = {},
  defaultValuesProps?: QueryState<KM>,
): UseQueryStateReturn<KM> {
  type InnerQueryState = QueryState<KM>;

  const { restoreOnPathnameChange, startTransition, unstableShallow = true } = options;

  const [defaultValues, setDefaulValues] = useState(defaultValuesProps);

  // next.js and native history navigation
  // ---

  const initialSearchParams = useSearchParams();

  // NOTE: internal state is needed because in case of shallow navigation
  // next.js's router will not receive an update.
  const [internalSearchParams, setInternalSearchParams] = useState<URLSearchParams>(
    // NOTE: return type of next's useSearchParams is not quite compatible
    // with URLSearchParams, but pretty sure they are the same under the hood,
    // the issue is types that they specify.
    () => {
      const newSearchParam = new URLSearchParams(initialSearchParams.toString());
      if (!defaultValues) return newSearchParam;
      Object.keys(defaultValues).forEach((key) => {
        const nextValue = defaultValues[key as keyof InnerQueryState];
        if (!keyMap[key]) return;
        if (isNil(nextValue) || nextValue === keyMap[key].defaultValue) {
          newSearchParam.delete(key);
        } else {
          const { into: stringify } = keyMap[key];
          newSearchParam.set(key, stringify(nextValue));
        }
      });
      return newSearchParam;
    },
  );

  const router = useRouter();
  const setSearchParams = useEventCallback<SetSearchParams>((nextInit, navigateOptions) => {
    const next = typeof nextInit === "function" ? nextInit(new URLSearchParams(internalSearchParams)) : nextInit;
    setInternalSearchParams(new URLSearchParams(next));

    const searchParams = new URLSearchParams(next);
    // TODO: Make a options in a queryTypes to include it in the URL param or not
    searchParams.delete("evWeights");
    searchParams.delete("expectedValueWeights");
    searchParams.delete("advanceFilter");

    const search = searchParams.toString();
    if (navigateOptions?.unstableShallow) {
      unstableShallowNavigate(`${window.location.href.split("?")[0]}${search ? `?${search}` : ""}`, "replace");
    } else {
      router.replace(`?${search}`, { scroll: false });
    }
  });

  // query state handling
  // ---

  // NOTE: parseCache is needed to make sure that arrays or objects that come
  // out of `parse` func will change only if raw value has changed (eg. we'll
  // return refs to those values from cache. this is to help prevent unwanted
  // re-renders when those values are being passed to hook deps or component
  // props).
  const parseCache = useRef<Record<string, unknown>>({});

  // NOTE: internalQueryState allows to make updates to the ui immediately if
  // startTransition is provided, without a need to wait for a request to server
  // component.
  const [internalQueryState, setInternalQueryState] = useState(() =>
    parseQueryState(internalSearchParams, keyMap, parseCache.current),
  );
  useEffect(
    () => {
      const nextInternalQueryState = parseQueryState(internalSearchParams, keyMap, parseCache.current);
      setInternalQueryState(nextInternalQueryState);
    },
    // Update the state values only when the relevant keys change.
    // Because we're not calling getValues in the function argument
    // of useMemo, but instead using it as the function to call,
    // there is no need to pass it in the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.keys(keyMap).map((key) => internalSearchParams.get(key)),
  );

  const setQueryState = useEventCallback<SetQueryState<KM>>((nextInit, clearOthers) => {
    const diff = typeof nextInit === "function" ? nextInit(internalQueryState) : nextInit;
    const nextSearchParams = new URLSearchParams(internalSearchParams);
    (clearOthers ? Object.keys(keyMap) : Object.keys(diff)).forEach((key) => {
      const nextValue = diff[key as keyof InnerQueryState];
      if (!keyMap[key]) return;
      if (isNil(nextValue) || nextValue === keyMap[key].defaultValue) {
        nextSearchParams.delete(key);
      } else {
        const { into: stringify } = keyMap[key];
        nextSearchParams.set(key, stringify(nextValue));
      }
    });

    setInternalQueryState(parseQueryState(nextSearchParams, keyMap, parseCache.current));

    if (startTransition && !unstableShallow) {
      startTransition(() => {
        setSearchParams(nextSearchParams);
      });
    } else {
      setSearchParams(nextSearchParams, { unstableShallow });
    }
  });

  const clearQueryState = useEventCallback(() => {
    const nextSearchParams = new URLSearchParams(internalSearchParams);
    Object.keys(keyMap).forEach((key) => {
      nextSearchParams.delete(key);
    });

    if (startTransition && !unstableShallow) {
      startTransition(() => setSearchParams(nextSearchParams));
    } else {
      setSearchParams(nextSearchParams, { unstableShallow });
    }
    setInternalQueryState(parseQueryState(nextSearchParams, keyMap, parseCache.current));
  });

  // cross-page query state restoration
  // ---
  //
  // TODO: this might be broken, figure out a better way to handle cross-page
  // query state restoration

  const pathname = usePathname();
  const prevPathname: typeof pathname = usePrevious(pathname) || pathname;
  const prevQueryState: typeof internalQueryState = usePrevious(internalQueryState) || internalQueryState;
  useIsomorphicLayoutEffect(() => {
    if (restoreOnPathnameChange && pathname !== prevPathname) {
      setQueryState(prevQueryState);
    }
  }, [restoreOnPathnameChange, pathname, prevPathname, setQueryState, prevQueryState]);

  useEffect(() => {
    if (defaultValues) setQueryState(defaultValues);
  }, [defaultValues, setQueryState]);

  // ----

  return [
    internalQueryState,
    setQueryState,
    {
      // fix with for default values
      // isQueryStateClean: isQueryStateClean(internalQueryState, keyMap),
      isQueryStateClean: internalSearchParams.toString() === "",
      clearQueryState,
      searchParams: internalSearchParams,
      setDefaulFilterValues: setDefaulValues,
    },
  ];
}
