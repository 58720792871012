import { CommonFilterType, KeyOfPages } from "@/components/Common/Filters/commonFilterType";
import { useCallback } from "react";
import useConvertAdvanceFilterToQueryVariableFn from "@/components/Common/Filters/useConvertAdvanceFilterToQueryVariable";
import { InputFilterBaseRequestType } from "@/user/user-filters/InputFilterBaseRequestType";
import { useFilterContextSelector } from "@/components/Common/Filters/FilterContext";
import removeUndefined from "@/utis/removeUndefined";
import useGenerateCommonVariableForSubsriptionFromFn from "./useGenerateCommonVariableForSubsription";
import { GetApiVariablesFromFiltersType } from "./useGetApiVariablesFromFilters.common";

function useGetApiVariablesFromFiltersFn(): GetApiVariablesFromFiltersType {
  const commonSubscriptionVariablesFn = useGenerateCommonVariableForSubsriptionFromFn();
  const advanceFilterFn = useConvertAdvanceFilterToQueryVariableFn();
  const getAdvanceFilter = useFilterContextSelector((ctx) => ctx.getAdvanceFilter);

  return useCallback((pathKey: KeyOfPages, queryStatePathKey: KeyOfPages, queryState: CommonFilterType) => {
    const commonSubscriptionVariables = commonSubscriptionVariablesFn(pathKey, queryStatePathKey, queryState, getAdvanceFilter);
    const advanceFilter = advanceFilterFn(pathKey, queryState, getAdvanceFilter);

    const requestVariable = {
      requestData: removeUndefined(commonSubscriptionVariables.variables),
    } as InputFilterBaseRequestType;
    const filters = advanceFilter ? advanceFilter.concat(requestVariable) : [requestVariable];

    return {
      requestVariableForPage: filters,
      skip: commonSubscriptionVariables.skip,
    };

  }, [advanceFilterFn, commonSubscriptionVariablesFn, getAdvanceFilter]);
}

export default useGetApiVariablesFromFiltersFn