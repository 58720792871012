import { gql, useQuery } from "@apollo/client";
import { USStateEnum, CAStateEnum } from "@/constants/StateEnum";
import CountriesEnum from "@/constants/CountriesEnum";
import { useUserSettingsContextSelector } from "@/user/user-settings/UserSettingsContext";
import { useMemo } from "react";

export const DEFAULT_LOCATION = USStateEnum.OTHERS;

export const GET_USER_LOCATION = gql`
  query GetUserLocation {
    user {
      ipGeoLookup {
        status
        region
        regionName
        query
        country
        countryCode
      }
    }
  }
`;

type GetUserLocation = {
  user: {
    ipGeoLookup: {
      status: string;
      region: string;
      regionName: string;
      query: string;
      country: string;
      countryCode: CountriesEnum;
    };
  };
};

export function getStateEnum(country?: CountriesEnum) {
  if (country === CountriesEnum.US) return USStateEnum;
  if (country === CountriesEnum.CA) return CAStateEnum;
  return [CountriesEnum.OTHERS];
}

function useGetDefaultLocation() {
  const location = useUserSettingsContextSelector((ctx) => ctx.globalSettings.location);
  const setCountry = useUserSettingsContextSelector((ctx) => ctx.setCountry);
  const setRegion = useUserSettingsContextSelector((ctx) => ctx.setRegion);
  const { data } = useQuery<GetUserLocation>(GET_USER_LOCATION, { skip: !!location?.region });
  const dataIpGeoLookup = data?.user.ipGeoLookup;
  if (!(location?.country && location?.region) && dataIpGeoLookup) {
    const { region, countryCode } = dataIpGeoLookup;
    const stateEnum = getStateEnum(dataIpGeoLookup?.countryCode);
    setCountry(
      CountriesEnum[countryCode as keyof typeof CountriesEnum]
        ? CountriesEnum[countryCode as keyof typeof CountriesEnum]
        : CountriesEnum.OTHERS,
    );
    setRegion(stateEnum[region as keyof typeof stateEnum] ? stateEnum[region as keyof typeof stateEnum] : DEFAULT_LOCATION);
  }

  const region =
    location?.region ||
    (dataIpGeoLookup?.region && dataIpGeoLookup?.region in USStateEnum
      ? USStateEnum[dataIpGeoLookup?.region as keyof typeof USStateEnum]
      : DEFAULT_LOCATION);

  const country =
    location?.country ||
    (dataIpGeoLookup?.countryCode && dataIpGeoLookup?.countryCode in CountriesEnum
      ? CountriesEnum[dataIpGeoLookup?.country as keyof typeof CountriesEnum]
      : CountriesEnum.OTHERS);

  return useMemo(
    () => ({
      region,
      country,
    }),
    [country, region],
  );
}

export default useGetDefaultLocation;
