import { useFilterContextSelector } from "@/components/Common/Filters/FilterContext";
import { useCallback } from "react";
import { CommonFilterType, KeyOfPages } from "@/components/Common/Filters/commonFilterType";
import { mapPathKeyToToolEnum } from "@/user/user-filters/common";
import { useUserSettingsContextSelector } from "@/user/user-settings/UserSettingsContext";
import { useUserFiltersContextSelector } from "@/user/user-filters/UserFiltersContext";
import useGetPathKey from "./useGetPathKey";
import useGetApiVariablesFromFiltersFn from "./useGetApiVariablesFromFilters";
import useRemoveSubscriptionVariableFromCommonFilterToJson from "./useRemoveSubscriptionVariableFromCommonFilterToJson";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function omit(obj: Record<string, any>, ...keys: any) {
  const keysToRemove = new Set(keys.flat()); // flatten the props, and convert to a Set

  return Object.fromEntries(
    // convert the entries back to object
    Object.entries(obj) // convert the object to entries
      .filter(([k]) => !keysToRemove.has(k)), // remove entries with keys that exist in the Set
  ) as CommonFilterType;
}

// Temp soln for now to remove other default filter value to save in other filter
export const removeUnnessesaryKey = (pathKey: KeyOfPages, queryState: CommonFilterType) => {
  let newQueryState = queryState;
  if (pathKey !== "expectedvalue") {
    newQueryState = omit(newQueryState, [
      "evWeights",
      "evFormulaWeight",
      "expectedValueFormulaCase",
      "minWidth",
      "showAllFormulas",
      "evBetAmountSort",
    ]);
  }
  if (pathKey !== "arbitrages") {
    newQueryState = omit(newQueryState, ["maximumLegs"]);
  }
  if (pathKey !== "middles") {
    newQueryState = omit(newQueryState, ["distance"]);
  }
  if (pathKey !== "odds-screen" && pathKey !== "game") {
    newQueryState = omit(newQueryState, [
      "avgColumn",
      "bestColumn",
      "holdColumn",
      "devigAverage",
      "timeSort",
      "holdSort",
      "devigFormulaCase",
      "devigFormulaWeight",
    ]);
  }
  if (pathKey !== "arbitrages" && pathKey !== "middles") {
    newQueryState = omit(newQueryState, ["ignoreDuplicateBetSites"]);
  }
  return newQueryState;
};

// If filterId is undefined it will update the current selected filterId
function useUpdatePageFilter<T = object>() {
  const queryState = useFilterContextSelector((ctx) => ctx.filterState);
  const pathKey = useGetPathKey();
  const selectedFilterId = useUserFiltersContextSelector((ctx) => ctx.selectedFilterId);
  const queryStatePathKey = useFilterContextSelector((ctx) => ctx.queryStatePathKey);
  const editUserFilter = useUserFiltersContextSelector((ctx) => ctx.editUserFilter);
  const userFilters = useUserFiltersContextSelector((ctx) => ctx.userFilters);
  const getApiVariablesFromFilters = useGetApiVariablesFromFiltersFn();
  const updateFilterProfileUrl = useUserSettingsContextSelector((ctx) => ctx.updateFilterProfileUrl);
  const removeSubscriptionVariableFromCommonFilterJson = useRemoveSubscriptionVariableFromCommonFilterToJson();

  // Maybe move this to useQueryState If possible
  const updatePageFilter = useCallback(
    (newVal: Partial<CommonFilterType<T>>, filterId?: string | undefined) => {
      const selectedFilter = userFilters?.find((filt) => filt.id === (filterId || selectedFilterId));
      const newData = { ...removeUnnessesaryKey(pathKey, queryState), ...newVal };
      const apiVariablesFromFilters = getApiVariablesFromFilters(pathKey, queryStatePathKey, newData);

      if (!selectedFilter) return;
      editUserFilter(
        [
          {
            ...selectedFilter,
            filters: apiVariablesFromFilters.requestVariableForPage,
            json: removeSubscriptionVariableFromCommonFilterJson(newData),
          },
        ],
        mapPathKeyToToolEnum(pathKey),
      );
      updateFilterProfileUrl({ key: pathKey, data: { name: selectedFilter.name, value: newData } });
    },
    [
      userFilters,
      pathKey,
      queryState,
      getApiVariablesFromFilters,
      queryStatePathKey,
      editUserFilter,
      removeSubscriptionVariableFromCommonFilterJson,
      updateFilterProfileUrl,
      selectedFilterId,
    ],
  );
  return updatePageFilter;
}

export default useUpdatePageFilter;
