import { useUser } from "@/user/UserContext";
import { useAppSelector } from "@/store/store";
import { FilterEnum } from "@/components/Common/Filters/FilterList";
import { League } from "@/components/Common/Enums/LeagueEnum";
import { useCallback } from "react";
import { useUserSettingsContextSelector } from "@/user/user-settings/UserSettingsContext";
import getSportsOfLeague from "@/utis/getSportsOfLeague";
import { CommonFilterType, KeyOfPages } from "@/components/Common/Filters/commonFilterType";
import { UserAdvanceFilterType } from "@/components/Common/Filters/FilterContext.common";
import { useRestrictedLeaguesFunction } from "./useRestrictedLeagues";
import useGetDefaultLocation from "./useGetDefaultLocation";
import generateCommonVariableForSubsriptionFromVariable from "../helper/generateCommonVariableForSubsriptionFromVariable";
import { useGetBetSitesFn } from "./useGetBetSites";

function useGenerateCommonVariableForSubsriptionFromFn() {
  const user = useUser();
  const defaultlocation = useGetDefaultLocation();
  const restrictedLeaguesFn = useRestrictedLeaguesFunction();
  const { betSitesParent, betGroups } = useAppSelector((state) => state.constantsReducer);
  const getPageBlockedBet = useUserSettingsContextSelector((ctx) => ctx.getPageBlockedBet);
  const getBetSites = useGetBetSitesFn();

  const nonAdvanceFilterBetSites = useCallback(
    (betSites: string[] | undefined, userAdvanceFilter: UserAdvanceFilterType[] | undefined) => {
      const advanceFilterBetSites = userAdvanceFilter
        ?.filter((advFilter) => advFilter.filterEnum === FilterEnum.SPORTSBOOKS)
        .map((advFilter) => advFilter.filterKey);
      return betSites?.filter((spb) => (advanceFilterBetSites ? !advanceFilterBetSites?.includes(spb) : true));
    },
    [],
  );

  return useCallback(
    (
      pathkey: KeyOfPages,
      queryStatePathKey: KeyOfPages,
      queryState: CommonFilterType,
      getAdvanceFilter: (advanceFilterProp?: string) => UserAdvanceFilterType[] | undefined,
    ) => {
      const restrictedLeagues = restrictedLeaguesFn(queryState.leagues);
      const { betSites, rOIRestrictedBetSites } = getBetSites(queryState.sportsBook, queryState.rOIRestrictedBetSites, pathkey);
      const userAdvanceFilter = getAdvanceFilter(queryState.advanceFilter);
      const commonSubscriptionVariables = generateCommonVariableForSubsriptionFromVariable({
        queryState,
        user,
        defaultlocation,
        restrictedLeagues,
        betSites: nonAdvanceFilterBetSites(betSites, userAdvanceFilter),
        rOIRestrictedBetSites,
        betSitesParent,
        pathkey,
      });
      const getCurrentPageBlockedBet = getPageBlockedBet(pathkey);

      let skip = false;

      if (queryStatePathKey !== pathkey) {
        skip = true;
      }
      // If leagueLimit is there and league variable is undefied, wait for the leagues to be fetched and get limited in useRestrictedLeagues
      if (user.restrictions.leagueLimit && commonSubscriptionVariables.variables.leagues === undefined) {
        skip = true;
      }

      if (getCurrentPageBlockedBet?.length) {
        commonSubscriptionVariables.variables.blockedBetHashCodes = getCurrentPageBlockedBet.map((bb) => bb.id);
      }

      userAdvanceFilter?.forEach((advFilter) => {
        if (advFilter.filterEnum === FilterEnum.SPORTSBOOKS) {
          commonSubscriptionVariables.variables.betSites = (commonSubscriptionVariables.variables.betSites || betSites)?.filter(
            // We are filtering the empty string because some how the empty string goes in the array and we don't know why yet
            // https://stratzteam.slack.com/archives/D02N7HD44RG/p1724510618878789
            (site) => site !== "" && site !== advFilter.filterKey,
          );
        }
        if (advFilter.filterEnum === FilterEnum.SPORTSBOOKS_RESTRICTED) {
          commonSubscriptionVariables.variables.rOIRestrictedBetSites = (
            commonSubscriptionVariables.variables.rOIRestrictedBetSites || betSites
          )?.filter(
            // We are filtering the empty string because some how the empty string goes in the array and we don't know why yet
            // https://stratzteam.slack.com/archives/D02N7HD44RG/p1724510618878789
            (site) => site !== "" && site !== advFilter.filterKey,
          );
        }
        if (advFilter.filterEnum === FilterEnum.LEAGUES) {
          commonSubscriptionVariables.variables.leagues = (
            commonSubscriptionVariables.variables.leagues || Object.values(League)
          )?.filter((site) => site !== advFilter.filterKey);
        }
        if (advFilter.filterEnum === FilterEnum.LEAGUES_RESTRICTED) {
          commonSubscriptionVariables.variables.rOIRestrictedLeagues = (
            commonSubscriptionVariables.variables.rOIRestrictedLeagues || Object.values(League)
          )?.filter((site) => site !== advFilter.filterKey);
        }
        if (advFilter.filterEnum === FilterEnum.BET_TYPES) {
          commonSubscriptionVariables.variables.betMarketTypes = commonSubscriptionVariables.variables.betMarketTypes?.filter(
            (site) => site !== advFilter.filterKey,
          );
        }
        if (advFilter.filterEnum === FilterEnum.BET_TYPES_GROUP) {
          commonSubscriptionVariables.variables.betMarketTypeGroups = (
            commonSubscriptionVariables.variables.betMarketTypeGroups || betGroups?.map((bmi) => bmi.value)
          )?.filter((site) => site !== advFilter.filterKey);
        }
        if (advFilter.filterEnum === FilterEnum.SPORTS) {
          commonSubscriptionVariables.variables.leagues = (
            commonSubscriptionVariables.variables.leagues || Object.values(League)
          )?.filter((leag) => advFilter.filterKey !== getSportsOfLeague(leag));
        }
        if (advFilter.filterEnum === FilterEnum.SPORTS_RESTRICTED) {
          commonSubscriptionVariables.variables.rOIRestrictedLeagues = (
            commonSubscriptionVariables.variables.rOIRestrictedLeagues || Object.values(League)
          )?.filter((leag) => advFilter.filterKey !== getSportsOfLeague(leag));
        }
      });

      return { variables: commonSubscriptionVariables.variables, skip };
    },
    [
      betGroups,
      betSitesParent,
      defaultlocation,
      getBetSites,
      getPageBlockedBet,
      nonAdvanceFilterBetSites,
      restrictedLeaguesFn,
      user,
    ],
  );
}

export default useGenerateCommonVariableForSubsriptionFromFn;
