import { useRef, useCallback } from "react";
import useIsomorphicLayoutEffect from "./useIsomorphicLayoutEffect";

// https://github.com/facebook/react/issues/14099#issuecomment-440013892
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const useEventCallback = <F extends (...args: any[]) => any>(fn: F) => {
  const ref = useRef<F>(fn);
  useIsomorphicLayoutEffect(() => {
    ref.current = fn;
  });
  return useCallback((...args: Parameters<F>): ReturnType<F> => ref.current.apply(null, args), []);
};

export default useEventCallback;
