/* eslint-disable @typescript-eslint/no-explicit-any */
import isNil from "@/utis/isNil";
import { type Converters } from "./converters";

export type ConverterKeyMapValue<T> = Converters<T> & {
  // defaultValue will not be transposed into the url. defaultValue should not
  // change, if it will change -> incorrect behaviour might occur because
  // queryState will not get updated.
  defaultValue?: T;
};

export type ConverterKeyMap<Map = any> = {
  [Key in keyof Map]: ConverterKeyMapValue<Map[Key]>;
};

type SelectNonUndefinedProps<T> = {
  [K in keyof T]-?: undefined extends T[K] ? never : K;
}[keyof T];

type SelectUndefinedProps<T> = {
  [K in keyof T]-?: undefined extends T[K] ? K : never;
}[keyof T];

type UnwrapConverterKeyMap<KM extends ConverterKeyMap> = {
  [K in keyof KM]: KM[K]["defaultValue"] extends NonNullable<ReturnType<KM[K]["from"]>>
    ? NonNullable<ReturnType<KM[K]["from"]>>
    : ReturnType<KM[K]["from"]> | undefined;
};

// NOTE: InternalQueryState only exists because it would not be nice to expose
// second generic argument that basically is a variable, and should not be
// touched from the outside, to the outside.
type InternalQueryState<KM extends ConverterKeyMap, KeyMap = UnwrapConverterKeyMap<KM>> = Pick<
  KeyMap,
  SelectNonUndefinedProps<KeyMap>
> &
  Partial<Pick<KeyMap, SelectUndefinedProps<KeyMap>>>;

export type QueryState<KM extends ConverterKeyMap> = InternalQueryState<KM>;

export function parseQueryState<KM extends ConverterKeyMap>(
  searchParams: URLSearchParams,
  keyMap: KM,
  parseCache: Record<string, any> = {},
) {
  type InnerQueryState = QueryState<KM>;
  const queryState: InnerQueryState = {} as any;

  Object.keys(keyMap).forEach((key) => {
    const { defaultValue } = keyMap[key];
    let parsedValue = defaultValue;

    const rawValue = searchParams.get(key);
    if (rawValue !== null) {
      if (!(rawValue in parseCache)) {
        const { from: parse } = keyMap[key];
        parsedValue = parse(rawValue);
      }
    }
    if (!isNil(parsedValue)) {
      queryState[key as keyof InnerQueryState] = parsedValue;
    }
  });

  return queryState;
}

export function isQueryStateClean<KM extends ConverterKeyMap>(queryState: QueryState<KM>, keyMap: KM) {
  const keys = Object.keys(keyMap) as Array<keyof QueryState<KM>>;
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];

    const value = queryState[key];
    if (!isNil(value)) {
      return false;
    }

    const { defaultValue } = keyMap[key];
    if (value !== defaultValue) {
      return false;
    }
  }

  return true;
}
