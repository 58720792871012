import { convertToApiDate } from "@/utis/ApiDateFormats";
import { CommonSubscriptionVariables } from "@/components/Common/CommonSubscriptionVariables";
import isNil from "@/utis/isNil";
import { DEFAULT_MIN_WIDTH, MAX_TAKE_DEFAULT } from "@/components/Common/Filters/commonFilterInitialState";
import { UserRestrictionType } from "@/user/UserRestrictions/common";
import { ConstantsType } from "@/store/constants";
import {
  convertEvFormulaWeightQueryToApiVariable,
  convertEvWeightQueryToApiVariable,
  defaultExpectedValueFormulaCase,
  defaultExpectedValueWeights,
  EV_FORMULA_WEIGHT_DEFAULT,
} from "@/components/ExpectedValue/helper";
import { CommonFilterType, hasPageRestrictionLimit, KeyOfPages } from "@/components/Common/Filters/commonFilterType";
import { PackageTypeEnum } from "@/user/common";
import { League } from "@/components/Common/Enums/LeagueEnum";
import { UserContextValue } from "@/user/UserContext";
import { InputIncludeExcludeRequestType } from "@/components/Block/Exclude/common";
import useRestrictedLeagues from "../hooks/useRestrictedLeagues";
import useGetDefaultLocation from "../hooks/useGetDefaultLocation";
import { getBetSiteFromSister } from "../hooks/useGetBetSiteFromSister";
import isPackageTypeFree from "./isPackageTypeFree";

// This used to be a hook, We have made this function as well instead of just jook to use it with advance filter as well.

export const getRoiRestrictedLeagues = (
  rOIRestrictedLeagues: League[] | undefined | null,
  leagueLimit: UserRestrictionType["leagueLimit"],
  pathKey: KeyOfPages,
) => {
  if (!hasPageRestrictionLimit(pathKey)) return undefined;
  if (!leagueLimit) return [];
  return rOIRestrictedLeagues;
};

export type GenerateCommonVariableForSubsriptionFromVariableType = {
  queryState: Omit<Partial<CommonFilterType>, "advanceFilter">;
  user: UserContextValue;
  defaultlocation: ReturnType<typeof useGetDefaultLocation>;
  restrictedLeagues: ReturnType<typeof useRestrictedLeagues>;
  betSites: string[] | undefined;
  rOIRestrictedBetSites: string[] | undefined;
  betSitesParent: ConstantsType["betSitesParent"];
  pathkey: KeyOfPages;
};

function generateCommonVariableForSubsriptionFromVariable(globalValues: GenerateCommonVariableForSubsriptionFromVariableType): {
  variables: CommonSubscriptionVariables;
} {
  const { user, defaultlocation, restrictedLeagues, betSites, rOIRestrictedBetSites, betSitesParent } = globalValues;

  const { region, country } = defaultlocation;

  const betSitesVariable = user.restrictions.sportbookLimit ? betSites?.slice(0, user.restrictions.sportbookLimit) : betSites;
  const leaguesVariable = user.restrictions.leagueLimit
    ? (globalValues.queryState.leagues || restrictedLeagues)?.slice(0, user.restrictions.leagueLimit)
    : globalValues.queryState.leagues;

  const isPackageTypeFreeAndDataLimited =
    isPackageTypeFree(user.data?.activePackageType) && hasPageRestrictionLimit(globalValues.pathkey);

  const getRoiRestrictedBetSitesVariable = () => {
    if (isPackageTypeFreeAndDataLimited) {
      return betSitesVariable;
    }
    // For advance user the roi restricted would always be empty
    // Putting this to check if this solve one isue https://discord.com/channels/1171897951648489532/1355508453703155814
    if (user.data?.activePackageType === PackageTypeEnum.ADVANCED) {
      return [];
    }
    return rOIRestrictedBetSites;
  };
  const getRoiRestrictedLeaguesVariable = () => {
    if (isPackageTypeFreeAndDataLimited) {
      return leaguesVariable;
    }
    // For advance user the roi restricted would always be empty
    // Putting this to check if this solve one isue https://discord.com/channels/1171897951648489532/1355508453703155814
    if (user.data?.activePackageType === PackageTypeEnum.ADVANCED) {
      return [];
    }
    return getRoiRestrictedLeagues(
      globalValues.queryState.rOIRestrictedLeagues,
      user.restrictions.leagueLimit,
      globalValues.pathkey,
    );
  };

  const variables = {
    betSites: isPackageTypeFreeAndDataLimited ? [] : betSitesVariable?.filter((data) => data !== ""),
    leagues: isPackageTypeFreeAndDataLimited ? [] : leaguesVariable,
    afterDateTime: convertToApiDate(globalValues.queryState.startDateTime),
    beforeDateTime: convertToApiDate(globalValues.queryState.endDateTime),
    minAmericanOdds: globalValues.queryState.minAmericanOdds,
    maxAmericanOdds: globalValues.queryState.maxAmericanOdds,
    upcomingGameStatus: globalValues.queryState.upcomingGameStatus,
    liveGameStatus: globalValues.queryState.liveGameStatus,
    futureHours: globalValues.queryState.futureHours,
    beforeHours: globalValues.queryState.beforeHours,
    foundSecondsAgo: globalValues.queryState.foundSecondsAgo,
    minROI: globalValues.queryState.minRoi,
    maxROI: isNil(user.restrictions.rOILimit) ? globalValues.queryState.maxRoi : user.restrictions.rOILimit || undefined,
    searchQuery: globalValues.queryState.search,
    betMarketTypes: globalValues.queryState.betMarketInfo,
    betMarketTypeGroups: globalValues.queryState.betMarketTypeGroups,
    region,
    country,
    take: isNil(user.restrictions.maxResult)
      ? globalValues.queryState.take || MAX_TAKE_DEFAULT
      : user.restrictions.maxResult || undefined,
    mustIncludeBetSite: getBetSiteFromSister(betSitesParent, globalValues.queryState.mustIncludeBetSite),
    rOIRestrictedBetSites: getRoiRestrictedBetSitesVariable(),
    rOIRestrictedLeagues: getRoiRestrictedLeaguesVariable(),
    minMaxWager: globalValues.queryState.minMaxWager,
    competitionData: globalValues.queryState.competitionData,

    // search filter
    isInclude: globalValues.queryState.isInclude,
    includeExcludes: globalValues.queryState.includeExcludes?.map(({ expirationDate, __typename, ...rest }) => rest) as Omit<
      InputIncludeExcludeRequestType,
      "expirationDate"
    >[],

    // block duplicate bets (only for arbitrage and middles)
    ignoreDuplicateBetSites:
      globalValues.pathkey === "arbitrages" || globalValues.pathkey === "middles"
        ? globalValues.queryState.ignoreDuplicateBetSites
        : undefined,
    // Arbitrage
    maximumLegs: globalValues.pathkey === "arbitrages" ? globalValues.queryState.maximumLegs : undefined,

    // Middles
    distance: globalValues.pathkey === "middles" ? globalValues.queryState.distance : undefined,

    // Expected Value variables
    expectedValueWeights:
      globalValues.pathkey === "expectedvalue"
        ? convertEvWeightQueryToApiVariable(globalValues.queryState.evWeights) || defaultExpectedValueWeights
        : undefined,
    expectedValueFormulaWeight:
      globalValues.pathkey === "expectedvalue"
        ? convertEvFormulaWeightQueryToApiVariable(globalValues.queryState.evFormulaWeight) || EV_FORMULA_WEIGHT_DEFAULT
        : undefined,
    expectedValueFormulaCase:
      globalValues.pathkey === "expectedvalue"
        ? globalValues.queryState.expectedValueFormulaCase || defaultExpectedValueFormulaCase
        : undefined,
    minWidth: globalValues.pathkey === "expectedvalue" ? globalValues.queryState.minWidth || DEFAULT_MIN_WIDTH : undefined,
  };

  return {
    variables,
  };
}

export default generateCommonVariableForSubsriptionFromVariable;
